<template>
    <div>
        <LaBaseForm
            :form-data="{
                id: id,
                name: name,
                email: email,
                publisher: selectedPublisher,
                permissions: permissions,
                showPayments: showPayments,
            }"
            id="user-form"
            :vuex-action-mutation="formAction"
            :button="isEdit ? 'Save' : 'Add new user'"
            buttonPortalTo="modalFooter"
        >
            <LaBaseInput v-model="name" name="name" label="Name" :rules="rules.name" :error="errors.name" />
            <LaBaseInput v-model="email" name="email" label="Email" :rules="rules.email" :error="errors.email" />
            <!--            <LaBaseCheckbox v-model="showPayments" name="payments" label="Show payments" />-->
        </LaBaseForm>
    </div>
</template>

<script>
import { computed, ref } from "vue";
import LaBaseForm from "@/components/BaseForm";
//import LaBaseSelect from "@/components/inputs/BaseSelect";
import LaBaseInput from "@/components/inputs/BaseInput";
import store from "@/store/store";
import { actionTypes as usersActionTypes, getterTypes as usersGetterTypes } from "@/store/Users/types";
import { getterTypes as publisherGetterTypes } from "@/store/Publisher/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import { isRequired, isValidEmail } from "@/helpers/rules.js";
import { getterTypes as loggedUserGetterTypes } from "@/store/LoggedUser/types";

export default {
    name: "LaUserForm",
    components: {
        LaBaseForm,
        LaBaseInput,
        //LaBaseSelect,
    },
    setup() {
        let user = computed(() => store.getters[dialogGetterTypes.getDialog].item);
        const userRights = computed(() => store.getters[loggedUserGetterTypes.getUserRights]);
        const errors = computed(() => store.getters[usersGetterTypes.getUserFormErrors] || {});
        const isSuperAdmin = computed(() => true || (userRights.value && userRights.value["super_admin"]));

        let name = ref("");
        let email = ref("");
        let showPayments = ref(false);
        let formAction = usersActionTypes.addData;
        let isEdit = ref(false);
        let id = ref(null);
        let permissions = [];
        const publishers = store.getters[publisherGetterTypes.getPublishers];
        let selectedPublisher = computed(() => store.getters[publisherGetterTypes.getSelectedPublisher]);

        const rules = ref({
            name: [isRequired()],
            email: [isRequired(), isValidEmail()],
        });

        if (user.value) {
            isEdit.value = true;
            formAction = usersActionTypes.editData;
            id.value = user.value.id;
            name.value = user.value.name || "";
            email.value = user.value.email;
            showPayments.value = user.value.showPayments;
            permissions = user.value.permissions;
        }

        return {
            id,
            name,
            email,
            showPayments,
            formAction,
            isEdit,
            rules,
            publishers,
            permissions,
            isSuperAdmin,
            selectedPublisher,
            errors,
        };
    },
};
</script>

<style scoped></style>
