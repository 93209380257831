import axios from "@/plugins/axios";
import { actionTypes, mutationTypes } from "./types";
import cloneDeep from "lodash.clonedeep";
import { showMessage } from "@/helpers/messages";
import { mutationTypes as flashMessagesMutationTypes } from "@/store/FlashMessages/types";
import { MESSAGE_STATE } from "@/store/FlashMessages/state";

function addPaymentRight(permissions, publisher) {
    const viewPayment = {
        type: "right",
        name: "view_payment",
        id: "",
        target: { admin: false, publisher: publisher, website: "all" },
    };
    permissions.push(viewPayment);
    return permissions;
}

function getBadInviteMessage(email) {
    return {
        header: `Invite was not sent to user ${email}.`,
        description: "Please check if email exists or try again later.",
        messageState: MESSAGE_STATE.WARN,
    };
}

export const actions = {
    async [actionTypes.getData]({ commit }) {
        commit(mutationTypes.usersGetDataStart);
        return axios({
            method: "get",
            url: "manage_users",
        }).then(
            (data) => {
                commit(mutationTypes.usersGetDataSuccess, data.data);
            },
            () => {
                commit(mutationTypes.usersGetDataFailure);
            }
        );
    },
    async [actionTypes.addData]({ commit }, { name, email, publisher, showPayments }) {
        commit(mutationTypes.userAddStart);
        let permissions = [
            {
                type: "group",
                name: "account",
                id: "",
                target: { admin: false, publisher: publisher, website: "all" },
            },
        ];
        let body = {
            action: "add",
            user: {
                id: "",
                email: email,
                name: name,
                permissions: permissions,
                brs_domains: [],
                is_lite: true,
            },
        };

        if (showPayments) {
            permissions = addPaymentRight(permissions, publisher);
        }

        return axios({
            method: "post",
            url: "manage_users/user",
            data: body,
        }).then(
            (data) => {
                showMessage(data.data, "creat", "user");
                if (data.data.ok) {
                    commit(mutationTypes.userAddSuccess, {
                        id: data.data.id,
                        name: name,
                        email: email,
                        permissions: permissions,
                        showPayments: showPayments,
                    });
                } else {
                    commit(mutationTypes.userAddEditErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.userAddFailure);
            }
        );
    },
    async [actionTypes.editData]({ commit }, { id, name, email, publisher, showPayments, permissions }) {
        let perm = cloneDeep(permissions);
        commit(mutationTypes.userEditStart);
        let body = {
            action: "save",
            user: {
                id: id,
                email: email,
                name: name,
                permissions: perm,
                brs_domains: [],
            },
        };
        if (showPayments) {
            perm = addPaymentRight(perm, publisher);
        } else {
            perm.forEach((p) => {
                if (p.type === "right" && p.name === "view_payment") {
                    const index = perm.indexOf(p);
                    perm.splice(index, 1);
                }
            });
        }

        return axios({
            method: "post",
            url: "manage_users/user",
            data: body,
        }).then(
            (data) => {
                showMessage(data.data, "sav", "user");
                if (data.data.ok) {
                    commit(mutationTypes.replaceUserInStore, { user: { id: id }, replaceData: body.user });
                } else {
                    commit(mutationTypes.userAddEditErrors, data.data.error);
                }
            },
            () => {
                commit(mutationTypes.userEditFailure);
            }
        );
    },
    async [actionTypes.deleteData]({ commit }, { id }) {
        commit(mutationTypes.userDeleteStart);
        return axios({
            method: "delete",
            url: `manage_users/user/${id}`,
        }).then(
            (data) => {
                showMessage(data.data, "delet", "user");
                commit(mutationTypes.userDeleteSuccess, data.data);
            },
            () => {
                commit(mutationTypes.userDeleteFailure);
            }
        );
    },
    async [actionTypes.inviteUser]({ commit }, { id, email }) {
        commit(mutationTypes.userInviteStart);
        return axios({
            method: "get",
            url: `la/manage_users/invite/${id}`,
        }).then(
            (data) => {
                if (data.data && data.data["invite"]) {
                    commit(mutationTypes.userInviteSuccess);
                    commit(flashMessagesMutationTypes.addMessageToQueue, {
                        header: `Invite was sent to user ${email}`,
                        description: "",
                        messageState: MESSAGE_STATE.SUCCESS,
                    });
                } else {
                    commit(flashMessagesMutationTypes.addMessageToQueue, getBadInviteMessage(email));
                }
            },
            () => {
                commit(mutationTypes.userInviteFailure);
                commit(flashMessagesMutationTypes.addMessageToQueue, getBadInviteMessage(email));
            }
        );
    },
};
