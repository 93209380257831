<template>
    <div v-if="publisher || !isEdit">
        <LaBaseForm
            :form-data="
                isEdit
                    ? {
                          id: publisher.id,
                          name: name,
                          status: publisherStatus,
                          currency: publisherCurrency,
                          performax_id: performaxId,
                          zoho_id: zohoId,
                      }
                    : {
                          name: name,
                          status: publisherStatus,
                          performax_id: performaxId,
                          zoho_id: zohoId,
                      }
            "
            :vuex-action-mutation="formAction"
            :button="isEdit ? 'Save' : 'Add publisher'"
            id="publisher"
            buttonPortalTo="modalFooter"
        >
            <LaBaseInput
                data-test="publisher-name"
                v-model="name"
                name="name"
                label="Name"
                id="name"
                :rules="rules.name"
                :error="errors.name"
            />

            <InputContainer label="Status" name="status">
                <div class="base-input-container">
                    <LaBaseSelect
                        data-test="publisher-status"
                        :items="statusArr"
                        v-model="publisherStatus"
                        :stretch="true"
                        :searchable="false"
                    >
                    </LaBaseSelect>
                </div>
            </InputContainer>

            <InputContainer label="Currency" name="currency">
                <div class="base-input-container">
                    <LaBaseSelect
                        data-test="publisher-currency"
                        :items="currencyArr"
                        v-model="publisherCurrency"
                        :stretch="true"
                        :searchable="false"
                    >
                    </LaBaseSelect>
                </div>
            </InputContainer>

            <LaBaseInput
                data-test="publisher-performax-id"
                v-model="performaxId"
                name="performaxId"
                label="Performax ID"
                id="performax-id"
                type="number"
            />

            <LaBaseInput
                data-test="publisher-zoho-id"
                v-model="zohoId"
                name="zohoId"
                label="Zoho ID"
                id="zoho-id"
                type="text"
            />
        </LaBaseForm>
    </div>
</template>

<script>
import LaBaseForm from "@/components/BaseForm";
import LaBaseInput from "@/components/inputs/BaseInput";
import LaBaseSelect from "@/components/inputs/BaseSelect";
import InputContainer from "@/components/inputs/InputContainer";
import { computed, ref } from "vue";
import { isRequired } from "@/helpers/rules";
import store from "@/store/store";
import { actionTypes, getterTypes } from "@/store/Publisher/types";
import { getterTypes as dialogGetterTypes } from "@/store/Dialog/types";
import { list_of_currencies, default_currency } from "@/helpers/data";

export default {
    name: "PublisherForm",
    components: {
        LaBaseForm,
        InputContainer,
        LaBaseInput,
        LaBaseSelect,
    },
    setup() {
        let isEdit = ref(false);
        let name = ref("");
        let publisherStatus = ref(default_currency);
        let publisherCurrency = ref("");
        let performaxId = ref("");
        let zohoId = ref("");
        let formAction = actionTypes.createPublisher;
        const publisher = computed(() => store.getters[dialogGetterTypes.getDialog].item);

        publisherStatus.value = "Active";
        publisherCurrency.value = default_currency;

        if (publisher.value) {
            isEdit = true;
            name.value = publisher.value.name;
            publisherStatus.value = publisher.value.status;
            if (publisher.value.currency) {
                publisherCurrency.value = publisher.value.currency;
            }
            performaxId.value = publisher.value.performax_id;
            zohoId.value = publisher.value.zoho_id;
            formAction = actionTypes.editPublisher;
        }
        const rules = {
            name: [isRequired()],
        };

        const errors = computed(() => store.getters[getterTypes.getCreatePublisherErrors] || {});
        const statusArr = ["Active", "Inactive", "New", "Light Demo", "Onboarding"];
        const currencyArr = list_of_currencies;

        return {
            isEdit,
            formAction,
            rules,
            errors,
            name,
            performaxId,
            zohoId,
            publisherStatus,
            publisherCurrency,
            statusArr,
            currencyArr,
            publisher,
        };
    },
};
</script>

<style scoped></style>
